


































import {Vue, Component, Prop} from 'vue-property-decorator'
import {BContainer, BRow, BCol, BCard, BAvatar} from 'bootstrap-vue'
import {ApplicationList} from "@/helpers/APP_HELPER"

@Component({
  components: {
    BContainer, BRow, BCol,
    BCard,
    BAvatar,
  }
})
export default class AppSelector extends Vue {
  applications = ApplicationList

  async created() {
    await this.$store.dispatch("user/GetMeScopes");
  }

  async beforeMount() {
    document.body.classList.remove('light-layout')
    document.body.classList.add('dark-layout')
  }



  async SET_APP(app: any): Promise<void> {
    this.$store.commit('user/SET_APP', app);
    await this.$router.push({name: 'home'}).catch(x => {
      //
    });
  }
}
